import { useMutation, useQuery } from "@tanstack/react-query";
import Sidebar from "../components/Sidebar";
import CustomDataTable from "../CustomDataTable";
import { getAllJournalist, SuspendJournalist, UnsuspendJournalist, UnverifyJournalist, VerifyJournalist } from "../api";
import noavatar from "../assets/pixabay.png";
import { MdDataUsage } from "react-icons/md";
import { MdDoNotDisturb } from "react-icons/md";
import { MdMobileFriendly } from "react-icons/md";
import { MdMobileOff } from "react-icons/md";
import { MdAddCircleOutline } from "react-icons/md";

import { useState } from "react";
import Swal from "sweetalert2";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import ViewJournalist from "../modal/ViewJournalist";
import Modal from "../modal/Modal";
import searchnow from "../assets/search-normal11.svg"
import ReactPaginate from "react-paginate";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";

const Journalist = () => {
    const { user } = useProtectedRoutesContext();
    const [isdetailsOpen, setIsdetailsOpen] = useState(false);
    const [selectedjournalist, setselectedjournalist] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1); //



    const { data: getalljournalist } = useQuery({
        queryKey: ["getAllJournalist", searchText, currentPage],
        queryFn: () => getAllJournalist({ q: searchText }, currentPage),
    });
    const { mutate: suspendJournalist } = useMutation(SuspendJournalist, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
        },
        onError: (error) => renderErrorMessage(error.message),
    });

    const { mutate: unsuspendJournalist } = useMutation(UnsuspendJournalist, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
        },
        onError: (error) => renderErrorMessage(error.message),
    });

    const { mutate: verifyJournalist } = useMutation(VerifyJournalist, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
        },
        onError: (error) => renderErrorMessage(error.message),
    });
    const { mutate: unverifyJournalist } = useMutation(UnverifyJournalist, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
        },
        onError: (error) => renderErrorMessage(error.message),
    });


    const columns = [
        {
            name: 'Avatar',
            selector: row => (
                <img
                    src={row.avatar || noavatar}
                    alt="User Avatar"
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
            ),
            compact: true,
            // width: "100px",
        },

        {
            name: 'Firstname',
            selector: row => row.first_name,
            compact: true,
            // width: "150px",
        },
        {
            name: 'Lastname',
            selector: row => row.last_name,
            compact: true,
            // width: "150px",
        },
        {
            name: 'Interests',
            selector: row => row.interests && row.interests.length > 0 ? row.interests.join(', ') : 'No interests',
            compact: true,
            width: "280px",
        },
        {
            name: 'Status',
            selector: row => row.status,
            compact: true,
            // width: "150px",

        },
        {
            name: 'is Verified',
            selector: row => row.is_verified === false ? "False" : "True",
            compact: true,
            // width: "150px",
        },

        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-4">
                    {row.status === "active" ? (
                        <MdDataUsage
                            className="cursor-pointer stroke-primary  mr-3"
                            onClick={() => {
                                Swal.fire({
                                    title: "Do you want to suspend this Journalist?",
                                    showDenyButton: false,
                                    showCancelButton: true,
                                    confirmButtonText: "Suspend Journalist",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        suspendJournalist(
                                            { journalist: row.uuid },
                                            {
                                                onSuccess: () => {
                                                    Swal.fire({
                                                        title: 'Suspended successfully!',
                                                        icon: 'success',
                                                    }).then(() => {
                                                        window.location.reload();
                                                    });
                                                },
                                                onError: (error) => {
                                                    Swal.fire({
                                                        title: 'Error suspending Journalist!',
                                                        text: error.message,
                                                        icon: 'error',
                                                    });
                                                },
                                            }
                                        );
                                    }
                                });
                            }}
                        />
                    ) : (
                        <MdDoNotDisturb
                            className="cursor-pointer stroke-primary  mr-3"
                            onClick={() => {
                                Swal.fire({
                                    title: "Do you want to unsuspend this Journalist?",
                                    showDenyButton: false,
                                    showCancelButton: true,
                                    confirmButtonText: "Unsuspend Journalist",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        unsuspendJournalist(
                                            { journalist: row.uuid },
                                            {
                                                onSuccess: () => {
                                                    Swal.fire({
                                                        title: 'Unsuspended successfully!',
                                                        icon: 'success',
                                                    }).then(() => {
                                                        window.location.reload();
                                                    });
                                                },
                                                onError: (error) => {
                                                    Swal.fire({
                                                        title: 'Error unsuspending Journalist!',
                                                        text: error.message,
                                                        icon: 'error',
                                                    });
                                                },
                                            }
                                        );
                                    }
                                });
                            }}
                        />
                    )}
                    {/* <MdMobileFriendly /> */}
                    {row.is_verified === false ? (
                        <MdMobileOff
                            className="cursor-pointer stroke-primary  mr-3"
                            onClick={() => {
                                Swal.fire({
                                    title: "Do you want to verify this Journalist?",
                                    showDenyButton: false,
                                    showCancelButton: true,
                                    confirmButtonText: "Verify Journalist",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        verifyJournalist(
                                            { journalist: row.uuid },
                                            {
                                                onSuccess: () => {
                                                    Swal.fire({
                                                        title: 'Verify successfully!',
                                                        icon: 'success',
                                                    }).then(() => {
                                                        window.location.reload();
                                                    });
                                                },
                                                onError: (error) => {
                                                    Swal.fire({
                                                        title: 'Error verify Journalist!',
                                                        text: error.message,
                                                        icon: 'error',
                                                    });
                                                },
                                            }
                                        );
                                    }
                                });
                            }}
                        />
                    ) : (
                        <MdMobileFriendly
                            className="cursor-pointer stroke-primary  mr-3"
                            onClick={() => {
                                Swal.fire({
                                    title: "Do you want to unverify this Journalist?",
                                    showDenyButton: false,
                                    showCancelButton: true,
                                    confirmButtonText: "Unverify Journalist",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        unverifyJournalist(
                                            { journalist: row.uuid },
                                            {
                                                onSuccess: () => {
                                                    Swal.fire({
                                                        title: 'Unverify successfully!',
                                                        icon: 'success',
                                                    }).then(() => {
                                                        window.location.reload();
                                                    });
                                                },
                                                onError: (error) => {
                                                    Swal.fire({
                                                        title: 'Error unsuspending Journalist!',
                                                        text: error.message,
                                                        icon: 'error',
                                                    });
                                                },
                                            }
                                        );
                                    }
                                });
                            }}
                        />
                    )}
                    <MdAddCircleOutline
                        onClick={() => {
                            setselectedjournalist(row);
                            setIsdetailsOpen(true);
                        }}
                    />
                </div>
            ),
            // width: "80px",
            compact: true,
        },
    ];


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);

    };


    return (
        <>
            <div className="the-parent-sidebar">
                <Sidebar />
                <div className="main-left mt-5">
                    <div className="">
                        <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                    </div>
                    <div className="profile-search-div mb-5 mt-5">
                        <img src={searchnow} alt="Search" />
                        <input
                            placeholder="Search Journalist"
                            value={searchText} // Bind state to input value
                            onChange={(e) => setSearchText(e.target.value)} // Update state on input change
                        />
                    </div>
                    <CustomDataTable
                        columns={columns}
                        data={getalljournalist?.data?.data}
                    />
                    <ReactPaginate
                        previousLabel={' Previous'}
                        previousClassName="previous-class"
                        nextLabel={'Next '}
                        nextClassName="next-class"
                        pageCount={getalljournalist?.data?.data ? Math.ceil(getalljournalist?.data?.total / getalljournalist?.data?.per_page) : 0} // Assuming each page returns 10 items
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active-paginate'}
                        renderOnZeroPageCount={null}
                        breakLabel="..."
                    />
                </div>
            </div>

            <Modal isOpen={isdetailsOpen} setIsOpen={setIsdetailsOpen} title="View Journalist Details" size="small">
                <ViewJournalist selectedjournalist={selectedjournalist} setIsOpen={setIsdetailsOpen} />
            </Modal>

        </>
    )
}
export default Journalist;