import { useMutation, useQuery } from "@tanstack/react-query";
import Sidebar from "../components/Sidebar";
import CustomDataTable from "../CustomDataTable";
import { getAllAdmin, getAllSource, SuspendSource, UnsuspendSource } from "../api";
import noavatar from "../assets/pixabay.png";
import { MdDataUsage } from "react-icons/md";
import { MdDoNotDisturb } from "react-icons/md";
import searchnow from "../assets/search-normal11.svg"


import { useState } from "react";
import Swal from "sweetalert2";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import ReactPaginate from "react-paginate";

import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import Modal from "../modal/Modal";
import AddAdmin from "../modal/AddAdmin";



const Admins = () => {
    const { user } = useProtectedRoutesContext();
    const [isSuspendOpen, setIsSuspendOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1); //
    const [isEditAdminOpen, setIsEditAdminOpen] = useState(false);


    const { data: getalladmin } = useQuery({
        queryKey: ["getAllAdmin", searchText, currentPage],
        queryFn: () => getAllAdmin({ q: searchText }, currentPage),
    });
    const { mutate: suspendsource } = useMutation(SuspendSource, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
        },
        onError: (error) => renderErrorMessage(error.message),
    });

    const { mutate: unsuspendsource } = useMutation(UnsuspendSource, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
        },
        onError: (error) => renderErrorMessage(error.message),
    });


    const columns = [

        {
            name: 'Email',
            selector: row => row.email,
            compact: true,
            width: "350px",
        },
        {
            name: 'Firstname',
            selector: row => row.first_name,
            compact: true,
            width: "200px",
        },
        {
            name: 'Lastname',
            selector: row => row.last_name,
            compact: true,
            width: "200px",
        },

    ];
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);

    };


    return (
        <div className="the-parent-sidebar">
            <Sidebar />
            <div className="main-left">
                <div className="for-flex">
                    <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                    <button className="create-a-query" onClick={() => setIsEditAdminOpen(true)}>
                        Create an Admin
                    </button>
                </div>
                <div className="profile-search-div mb-5 mt-5">
                    <img src={searchnow} alt="Search" />
                    <input
                        placeholder="Search Admin"
                        value={searchText} // Bind state to input value
                        onChange={(e) => setSearchText(e.target.value)} // Update state on input change
                    />
                </div>
                <CustomDataTable
                    columns={columns}
                    data={getalladmin?.data?.data}
                />

                <ReactPaginate
                    previousLabel={' Previous'}
                    previousClassName="previous-class"
                    nextLabel={'Next '}
                    nextClassName="next-class"
                    pageCount={getalladmin?.data?.data ? Math.ceil(getalladmin?.data?.total / getalladmin?.data?.per_page) : 0} // Assuming each page returns 10 items
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active-paginate'}
                    renderOnZeroPageCount={null}
                    breakLabel="..."
                />
                <Modal isOpen={isEditAdminOpen} setIsOpen={setIsEditAdminOpen} title="Add Admin" size="large">
                    <AddAdmin setIsOpen={setIsEditAdminOpen} />
                </Modal>
            </div>
        </div>
    )
}
export default Admins;