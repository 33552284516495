import { useMutation, useQuery } from "@tanstack/react-query";
import { DeleteTag, getTags } from "../api";
import Sidebar from "../components/Sidebar";
import CustomDataTable from "../CustomDataTable";
import { format, parseISO } from "date-fns";
// import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import AddTags from "../modal/AddTags";
import Modal from "../modal/Modal";
import Swal from "sweetalert2";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";

const Tags = () => {
    const { user } = useProtectedRoutesContext();
    const [isEditTagsOpen, setIsEditTagsOpen] = useState(false);

    const { data: gettags } = useQuery({
        queryKey: ["getTags"],
        queryFn: () => getTags(),
    });
    const { mutate } = useMutation(DeleteTag, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);

        },
        onError: (error) => renderErrorMessage(

        ),

    });

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            compact: true,
            // width: "350px",
        },
        {
            name: 'Created At',
            selector: (row) => {
                const date = parseISO(row.created_at);
                return format(date, "MMM d, yyyy h:mma");
            },
            compact: true,
            // width: "500px",
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-4">
                    {/* <BiEditAlt className="cursor-pointer stroke-primary text-primary mr-3"
                    onClick={() => {
                        setselectedquery(row);
                        setIsEditModalOpen(true);
                    }}
                    /> */}
                    <MdDelete className="leak-extra stroke-primary "
                        onClick={() => {
                            Swal.fire({
                                title: "Do you want to delete this Tag?",
                                showDenyButton: false,
                                showCancelButton: true,
                                confirmButtonText: "Delete Tag",
                                denyButtonText: `Cancel`
                            }).then((result) => {

                                if (result.isConfirmed) {

                                    mutate(row?.uuid, {
                                        onSuccess: () => {
                                            Swal.fire({
                                                title: 'Tag deleted successfully!',
                                                icon: 'success',
                                            }).then(() => {

                                                window.location.reload();

                                            });
                                        },
                                        onError: (error) => {
                                            Swal.fire({
                                                title: 'Error deleting Tag!',
                                                text: error.message,
                                                icon: 'error',
                                            });
                                        },
                                    });
                                }
                            });
                        }}
                    />

                </div>
            ),
            width: "80px",
            compact: true,
        },
    ];


    return (
        <div>
            <div className="the-parent-sidebar">
                <Sidebar />
            </div>

            <div className="main-left">
                <div className="for-flex mb-5">
                    <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                    {/* <h2 className="good-greeting">Hello, {user?.first_name}</h2> */}
                    <button className="create-a-query" onClick={() => setIsEditTagsOpen(true)}>
                        Create a Tag
                    </button>
                </div>

                <CustomDataTable
                    columns={columns}
                    data={gettags?.data}
                />
            </div>
            <Modal isOpen={isEditTagsOpen} setIsOpen={setIsEditTagsOpen} title="Add Tags" size="large">
                <AddTags setIsOpen={setIsEditTagsOpen} />
            </Modal>
        </div>
    )
}


export default Tags;