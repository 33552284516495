import { useParams } from "react-router-dom";
import queryPerson from "../assets/query-person.png";
import paulPerson from "../assets/paul-picture.svg";
import clock from "../assets/clock.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DeletePitch, ReplyPitch, getPitchDetails, getQueryDetails } from "../api";
import { parseISO, format, formatDistanceToNow } from 'date-fns';
import { useState } from "react";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { Formik } from "formik";
import ReactPaginate from "react-paginate";
import jpeglevel from "../assets/greenjpeg.png"
import pdflevel from "../assets/Filetypes.png"
import bluepng from "../assets/bluepng.png"
import blackjpg from "../assets/blackjpg.png";
import tealjpg from "../assets/videomp4.png";
import { Helmet } from "react-helmet";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import CustomDataTable from "../CustomDataTable";
import { BsPersonCheckFill } from "react-icons/bs";
import Modal from "../modal/Modal";
import AllPitchReply from "../modal/AllPitchReply";
import { RiDeleteBin7Line } from "react-icons/ri";
import Swal from "sweetalert2";



const QueryDetails = () => {

    const [currentPage, setCurrentPage] = useState(1); //


    const resultsPerPage = 2;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);

    };
    const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
    const [selectedquery, setselectedquery] = useState(null);



    // const reloadPage = () => {
    //     window.location.reload();
    // };


    const { user } = useProtectedRoutesContext()

    let firstname = '';
    let lastname = '';





    const { queryId } = useParams()

    const { data, refetch } = useQuery({
        queryKey: ["getQueryDetails", queryId, currentPage],
        queryFn: () => getQueryDetails(queryId, currentPage),
        suspense: true,
    });
    const { data: pitchDetails } = useQuery({
        queryKey: ["getPitchDetails", queryId, currentPage],
        queryFn: () => getPitchDetails(queryId, currentPage),
        suspense: true,
    });


    const { mutate } = useMutation(DeletePitch, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);

        },
        onError: (error) => renderErrorMessage(

        ),

    });


    // const { mutate } = useMutation(ReplyPitch, {
    //     onSuccess: (data) => {
    //         renderSuccessMessage(data.message);
    //         refetch()
    //     },
    //     onError: (error) => renderErrorMessage(
    //         error.message
    //     ),

    // });

    // const handleReplySubmit = (queryId, pitchId, reply) => {
    //     mutate({ queryId, pitchId, replyText: reply });
    // };




    const columns = [
        {
            name: 'Pitch Title',
            selector: row => row.title,
            compact: true,
            width: "150px",
        },
        {
            name: 'Created At',
            selector: (row) => {
                const date = parseISO(row.created_at);
                return format(date, "MMM d, yyyy h:mma");
            },
            compact: true,
            width: "180px",
        },
        {
            name: 'Description',
            selector: row => row.description,
            compact: true,
            // width: "120px",
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-4">
                    <BsPersonCheckFill className="cursor-pointer stroke-primary text-primary mr-3"
                        onClick={() => {
                            setselectedquery(row.uuid);
                            setIsReplyModalOpen(true);
                        }}
                    />
                    < RiDeleteBin7Line className="cursor-pointer stroke-primary text-primary mr-3"
                        // onClick={() => {
                        //     setselectedquery(row);
                        // }}
                        onClick={() => {
                            Swal.fire({
                                title: "Do you want to delete this Pitch?",
                                showDenyButton: false,
                                showCancelButton: true,
                                confirmButtonText: "Delete Pitch",
                                denyButtonText: `Cancel`
                            }).then((result) => {

                                if (result.isConfirmed) {
                                    // Call mutate with leak uuid to delete the leak
                                    mutate(row?.uuid, {
                                        onSuccess: () => {
                                            Swal.fire({
                                                title: 'Pitch deleted successfully!',
                                                icon: 'success',
                                            }).then(() => {
                                                // Reload the page or re-fetch data after successful deletion
                                                window.location.reload(); // This will reload the page
                                                // Or, you can trigger a re-fetch of data instead if you don't want a full page reload
                                                // refetch(); // Assuming you have a useQuery with refetch
                                            });
                                        },
                                        onError: (error) => {
                                            Swal.fire({
                                                title: 'Error deleting Pitch!',
                                                text: error.message,
                                                icon: 'error',
                                            });
                                        },
                                    });
                                }
                            });
                        }}
                    />
                </div>
            ),
            width: "100px",
        }

    ];



    return (
        <div>
            <Helmet>
                <title>Query Details</title>
            </Helmet>
            <div className="container">
                <div className="for-flex">
                    <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                </div>
                <div className="query-details-container">
                    <div className="extended-div-container">
                        <div className="">
                            <div className="extended-admin">
                                <h4 className="extended-admin-text">{data?.data?.title}</h4>
                            </div>
                        </div>
                        {/* <div className="extended-mainline">
                        <div className="extended-line">
                            <div className="extended-line">
                                <h6 className="extended-header"><span> <img className="extended-img" src={calender} alt="" /></span>Extend deadline</h6>
                                <h6 className="extended-header ml-4"><span> <img className="extended-img" src={archieve} alt="" /></span>Archive</h6>
                            </div>
                        </div>
                    </div> */}
                        <h3 className="query-description1">Deadline:
                            <span className="the-deadline-zone">{data?.data?.queryDetails?.deadline ? format(parseISO(data?.data?.deadline), "MMM d, yyyy h:mma") : 'N/A'}</span></h3>
                    </div>

                    <h6 className="status-status">status</h6>
                    <div className="awaiting-review">
                        <img className="place-clock" src={clock} alt="" />
                        <h6 className="awaiting-text">{data?.data?.status}</h6>
                    </div>
                    <h3 className="query-description">Query Description</h3>
                    <h6 className="query-sub-description">{data?.data?.description}</h6>
                    {/* <h3 className="query-description">Deadline</h3> */}
                    {/* {data?.data?.deadline ? format(parseISO(data?.data?.deadline), "MMM d, yyyy h:mma") : 'N/A'} */}
                    <h3 className="query-description">Topics</h3>
                    <p className="query-sub-description">{data?.data?.tags ? data.data?.tags.join(', ') : 'N/A'}</p>
                    <h3 className="query-description">Media/News Outlet</h3>
                    <p className="query-sub-description">{data?.data?.media_outlet}</p>
                </div>


                <h3 className="query-description mb-5">
                    Pitches
                </h3>


                <CustomDataTable className="mb-5"
                    columns={columns}
                    data={pitchDetails?.data?.data}
                />

                <Modal isOpen={isReplyModalOpen} setIsOpen={setIsReplyModalOpen} title="View Pitch Replies" size="large">
                    <AllPitchReply
                        myquery={selectedquery}
                        setIsOpen={setIsReplyModalOpen} refetch={refetch} />
                </Modal>


                <ReactPaginate
                    previousLabel={'< Prev'}
                    previousClassName="previous-class"
                    nextLabel={'Next >'}
                    nextClassName="next-class"
                    pageCount={data?.data?.pitches ? Math.ceil(data?.data?.pitches?.total / resultsPerPage) : 0}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active-paginate'}
                    renderOnZeroPageCount={null}
                    breakLabel="..."

                />
            </div>
        </div>

    )
}





export default QueryDetails;