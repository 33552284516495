import { useMutation, useQuery } from "@tanstack/react-query";
import { DeleteOutlet, DeleteTag, getOutlet } from "../api";
import Sidebar from "../components/Sidebar";
import CustomDataTable from "../CustomDataTable";
import { format, parseISO } from "date-fns";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import Modal from "../modal/Modal";
import Swal from "sweetalert2";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import AddOutlet from "../modal/AddOutlet";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";

const MediaOutlet = () => {
    const { user } = useProtectedRoutesContext();
    const [isAddOutletOpen, setIsAddOutletOpen] = useState(false);

    const { data: getoutlet } = useQuery({
        queryKey: ["getoutlet"],
        queryFn: () => getOutlet(),
    });
    const { mutate } = useMutation(DeleteOutlet, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);

        },
        onError: (error) => renderErrorMessage(

        ),

    });

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            compact: true,
            // width: "350px",
        },
        {
            name: 'Created At',
            selector: (row) => {
                const date = parseISO(row.created_at);
                return format(date, "MMM d, yyyy h:mma");
            },
            compact: true,
            // width: "500px",
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-4">
                    {/* <BiEditAlt className="cursor-pointer stroke-primary text-primary mr-3"
                    onClick={() => {
                        setselectedquery(row);
                        setIsEditModalOpen(true);
                    }}
                    /> */}
                    <MdDelete className="leak-extra stroke-primary "
                        onClick={() => {
                            Swal.fire({
                                title: "Do you want to delete this Outlet?",
                                showDenyButton: false,
                                showCancelButton: true,
                                confirmButtonText: "Delete Outlet",
                                denyButtonText: `Cancel`
                            }).then((result) => {

                                if (result.isConfirmed) {

                                    mutate(row?.id, {
                                        onSuccess: () => {
                                            Swal.fire({
                                                title: 'Outlet deleted successfully!',
                                                icon: 'success',
                                            }).then(() => {

                                                window.location.reload();

                                            });
                                        },
                                        onError: (error) => {
                                            Swal.fire({
                                                title: 'Error deleting Outlet!',
                                                text: error.message,
                                                icon: 'error',
                                            });
                                        },
                                    });
                                }
                            });
                        }}
                    />

                </div>
            ),
            width: "80px",
            compact: true,
        },
    ];


    return (
        <div>
            <div className="the-parent-sidebar">
                <Sidebar />
            </div>

            <div className="main-left">
                <div className="mb-5">
                    <div className="for-flex">
                        <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                        <button className="create-a-query" onClick={() => setIsAddOutletOpen(true)}>
                            Create a Outlet
                        </button>
                    </div>
                    {/* <h2 className="good-greeting">Hello, {user?.first_name}</h2> */}

                </div>

                <CustomDataTable
                    columns={columns}
                    data={getoutlet?.data}
                />
            </div>
            <Modal isOpen={isAddOutletOpen} setIsOpen={setIsAddOutletOpen} title="Add Outlet" size="large">
                <AddOutlet setIsOpen={setIsAddOutletOpen} />
            </Modal>
        </div>
    )
}


export default MediaOutlet;