import { format, parseISO } from "date-fns";

const ViewJournalist = ({ selectedjournalist }) => {

    return (
        <>
            <div>
                <h1 className="the-journalist-data">Fullname: {selectedjournalist?.first_name} {selectedjournalist?.last_name}</h1>
                <h1 className="the-journalist-data">Email: {selectedjournalist?.email ? selectedjournalist?.email : "No Data"}</h1>
                <h1 className="the-journalist-data">Username: {selectedjournalist?.username ? selectedjournalist?.username : "No Data"}</h1>
                <h1 className="the-journalist-data">Is Verified: {selectedjournalist?.is_verified ? selectedjournalist?.is_verified : "No Data"}</h1>
                <h1 className="the-journalist-data">Website Url: {selectedjournalist?.website_url ? selectedjournalist?.website_url : "No Data"}</h1>
                <h1 className="the-journalist-data">Created At: {selectedjournalist?.created_at
                    ? format(parseISO(selectedjournalist.created_at), "MMM d, yyyy h:mma")
                    : "No Data"}</h1>
                <h1 className="the-journalist-data">Updated At: {selectedjournalist?.updated_at
                    ? format(parseISO(selectedjournalist.updated_at), "MMM d, yyyy h:mma")
                    : "No Data"}</h1>
                <h1 className="the-journalist-data">Bio: {selectedjournalist?.bio ? selectedjournalist?.bio : "No Data"}</h1>
            </div>
        </>
    )

}
export default ViewJournalist;