import { useMutation, useQuery } from "@tanstack/react-query";
import { DeletePitchReply, getPitchReply } from "../api";
import { useState } from "react";
import CustomDataTable from "../CustomDataTable";
import { format, parseISO } from "date-fns";
import { RiDeleteBin7Line } from "react-icons/ri";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import Swal from "sweetalert2";


const AllPitchReply = ({ myquery }) => {


    const [currentPage, setCurrentPage] = useState(1); //

    const { data: allpitchreply } = useQuery({
        queryKey: ["getPitchReply", myquery, currentPage],
        queryFn: () => getPitchReply(myquery, currentPage),
        suspense: true,
    });

    const { mutate } = useMutation(DeletePitchReply, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);

        },
        onError: (error) => renderErrorMessage(

        ),

    });
    const columns = [
        {
            name: 'Pitch Reply',
            selector: (row) => row.reply,
            compact: true,
            width: "420px",
        },
        {
            name: 'Created At',
            selector: (row) => {
                const date = parseISO(row.created_at);
                return format(date, "MMM d, yyyy h:mma");
            },
            compact: true,
            width: "150px",
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-4">
                    < RiDeleteBin7Line className="cursor-pointer stroke-primary text-primary mr-3"
                        // onClick={() => {
                        //     setselectedquery(row);
                        // }}
                        onClick={() => {
                            Swal.fire({
                                title: "Do you want to delete this Pitch Reply?",
                                showDenyButton: false,
                                showCancelButton: true,
                                confirmButtonText: "Delete Pitch Reply",
                                denyButtonText: `Cancel`
                            }).then((result) => {

                                if (result.isConfirmed) {
                                    // Call mutate with leak uuid to delete the leak
                                    mutate(row?.uuid, {
                                        onSuccess: () => {
                                            Swal.fire({
                                                title: 'Pitch Reply deleted successfully!',
                                                icon: 'success',
                                            }).then(() => {
                                                // Reload the page or re-fetch data after successful deletion
                                                window.location.reload(); // This will reload the page
                                                // Or, you can trigger a re-fetch of data instead if you don't want a full page reload
                                                // refetch(); // Assuming you have a useQuery with refetch
                                            });
                                        },
                                        onError: (error) => {
                                            Swal.fire({
                                                title: 'Error deleting Pitch Reply!',
                                                text: error.message,
                                                icon: 'error',
                                            });
                                        },
                                    });
                                }
                            });
                        }}
                    />
                </div>
            ),
        }
    ]


    return (
        <div>
            <CustomDataTable
                columns={columns}
                data={allpitchreply?.data?.data}
            />
        </div>
    )
}

export default AllPitchReply;