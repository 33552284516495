import { format, parseISO } from "date-fns";
import CustomDataTable from "../CustomDataTable";
// import resultimg from "../assets/EmptyState.svg"
import { FaRegBookmark } from "react-icons/fa6";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { archiveQuery, DeleteLeak, DeleteQuery, getAllQueries, getAlltheLeaks, getAlltheQueries } from "../api";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import Swal from "sweetalert2";
import { BiEditAlt } from "react-icons/bi";
import AddQuery from "../modal/AddQuery";
import Modal from "../modal/Modal";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import searchnow from "../assets/search-normal11.svg"
import { Helmet } from "react-helmet";
import Sidebar from "../components/Sidebar";
import { RiDeleteBin7Line } from "react-icons/ri";



const AllLeaks = ({ isAddQueryModalOpen, setIsAddQueryModalOpen }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1); //


    const resultsPerPage = 10;



    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedquery, setselectedquery] = useState(null);

    const { data: queries, refetch } = useQuery({
        queryKey: ["getAlltheLeaks", currentPage],
        queryFn: () => getAlltheLeaks(searchQuery, currentPage),
        suspense: true,
    });


    const { mutate } = useMutation(DeleteLeak, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);

        },
        onError: (error) => renderErrorMessage(

        ),

    });



    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);

    };


    const columns = [
        // {
        //     name: 'Leak Title',
        //     selector: row => row.title,
        //     compact: true,
        //     width: "300px",
        //     cell: (row) => (
        //         <Link className="cursor-pointer" 
        //         to={`/leaks/${row.uuid}`}>
        //             {row.title}
        //         </Link>
        //     ),
        // },
        {
            name: 'Leak',
            selector: row => row?.title,
            compact: true,
            width: "350px",
        },
        {
            name: 'Firstname',
            selector: row => row?.source_first_name,
            compact: true,
            width: "140px",
        },
        {
            name: 'Lastname',
            selector: row => row?.source_last_name,
            compact: true,
            width: "140px",
        },
        {
            name: 'Created At',
            selector: (row) => {
                const date = parseISO(row.created_at);
                return format(date, "MMM d, yyyy h:mma");
            },
            compact: true,
            width: "200px",
        },

        // {
        //     name: 'Pitch',
        //     selector: row => row.responses,
        //     compact: true,
        //     width: "80px"
        // },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-4">
                    < RiDeleteBin7Line className="cursor-pointer stroke-primary text-primary mr-3"
                        onClick={() => {
                            Swal.fire({
                                title: "Do you want to delete this Leak?",
                                showDenyButton: false,
                                showCancelButton: true,
                                confirmButtonText: "Delete Leak",
                                denyButtonText: `Cancel`
                            }).then((result) => {

                                if (result.isConfirmed) {
                                    mutate(row?.uuid, {
                                        onSuccess: () => {
                                            Swal.fire({
                                                title: 'Leak deleted successfully!',
                                                icon: 'success',
                                            }).then(() => {

                                                window.location.reload();

                                            });
                                        },
                                        onError: (error) => {
                                            Swal.fire({
                                                title: 'Error deleting Leak!',
                                                text: error.message,
                                                icon: 'error',
                                            });
                                        },
                                    });
                                }
                            });
                        }}
                    />
                </div>
            ),
            width: "70px",
            compact: true,
        },
    ];



    const handleSearch = () => {
        refetch();
    };


    return (
        <>

            <Helmet>
                <title>Queries</title>
            </Helmet>
            <div className="the-parent-sidebar">
                <Sidebar />
            </div>

            <div className="main-left">
                {/* <div className="the-background-log">
                    <img src={searchnow} alt="" onClick={handleSearch} />
                    <input src="" className="input-point" placeholder="Search queries by Title"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                    />
                </div> */}
                <div className="custom-query-table">
                    <CustomDataTable
                        columns={columns}
                        data={queries?.data?.data}

                    />
                </div>
                <ReactPaginate
                    previousLabel={' Previous'}
                    previousClassName="previous-class"
                    nextLabel={'Next '}
                    nextClassName="next-class"
                    pageCount={queries?.data?.data ? Math.ceil(queries?.data?.total / resultsPerPage) : 0}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active-paginate'}
                    renderOnZeroPageCount={null}
                    breakLabel="..."
                />
            </div>

        </>
    )
}
export default AllLeaks;
