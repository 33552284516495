import { useMutation, useQuery } from "@tanstack/react-query";
import Sidebar from "../components/Sidebar";
import CustomDataTable from "../CustomDataTable";
import { getAllSource, SuspendSource, UnsuspendSource } from "../api";
import noavatar from "../assets/pixabay.png";
import { MdDataUsage } from "react-icons/md";
import { MdDoNotDisturb } from "react-icons/md";
import searchnow from "../assets/search-normal11.svg"


import { useState } from "react";
import Swal from "sweetalert2";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import ReactPaginate from "react-paginate";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";


const Expert = () => {
    const { user } = useProtectedRoutesContext();
    const [isSuspendOpen, setIsSuspendOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1); //


    const { data: getallsource } = useQuery({
        queryKey: ["getAllSource", searchText, currentPage],
        queryFn: () => getAllSource({ q: searchText }, currentPage),
    });
    const { mutate: suspendsource } = useMutation(SuspendSource, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
        },
        onError: (error) => renderErrorMessage(error.message),
    });

    const { mutate: unsuspendsource } = useMutation(UnsuspendSource, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
        },
        onError: (error) => renderErrorMessage(error.message),
    });


    const columns = [
        {
            name: 'Avatar',
            selector: row => (
                <img
                    src={row.avatar || noavatar}
                    alt="User Avatar"
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
            ),
            compact: true,
            width: "60px",
        },
        {
            name: 'Email',
            selector: row => row.email,
            compact: true,
            width: "190px",
        },
        {
            name: 'Firstname',
            selector: row => row.first_name,
            compact: true,
            width: "100px",
        },
        {
            name: 'Lastname',
            selector: row => row.last_name,
            compact: true,
            width: "100px",
        },
        {
            name: 'Interests',
            selector: row => row.interests && row.interests.length > 0 ? row.interests.join(', ') : 'No interests',
            compact: true,
            width: "200px",
        },
        {
            name: 'Status',
            selector: row => row.status,
            compact: true,
        },

        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-4">
                    {row.status === "active" ? (
                        <MdDoNotDisturb
                            className="cursor-pointer stroke-primary text-primary mr-3"
                            onClick={() => {
                                Swal.fire({
                                    title: "Do you want to suspend this Expert?",
                                    showDenyButton: false,
                                    showCancelButton: true,
                                    confirmButtonText: "Suspend Expert",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        suspendsource(
                                            { expert: row.uuid },
                                            {
                                                onSuccess: () => {
                                                    Swal.fire({
                                                        title: 'Suspended successfully!',
                                                        icon: 'success',
                                                    }).then(() => {
                                                        window.location.reload();
                                                    });
                                                },
                                                onError: (error) => {
                                                    Swal.fire({
                                                        title: 'Error suspending Source!',
                                                        text: error.message,
                                                        icon: 'error',
                                                    });
                                                },
                                            }
                                        );
                                    }
                                });
                            }}
                        />
                    ) : (
                        <MdDataUsage
                            className="cursor-pointer stroke-primary text-primary mr-3"
                            onClick={() => {
                                Swal.fire({
                                    title: "Do you want to unsuspend this  Expert?",
                                    showDenyButton: false,
                                    showCancelButton: true,
                                    confirmButtonText: "Unsuspend Expert",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        unsuspendsource(
                                            { expert: row.uuid },
                                            {
                                                onSuccess: () => {
                                                    Swal.fire({
                                                        title: 'Unsuspended successfully!',
                                                        icon: 'success',
                                                    }).then(() => {
                                                        window.location.reload();
                                                    });
                                                },
                                                onError: (error) => {
                                                    Swal.fire({
                                                        title: 'Error unsuspending Expert!',
                                                        text: error.message,
                                                        icon: 'error',
                                                    });
                                                },
                                            }
                                        );
                                    }
                                });
                            }}
                        />
                    )}
                </div>
            ),
            width: "80px",
            compact: true,
        },
    ];
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);

    };


    return (
        <div className="the-parent-sidebar">
            <Sidebar />
            <div className="main-left">
                <div className="mt-5">
                    <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                </div>
                <div className="profile-search-div mb-5 mt-5">
                    <img src={searchnow} alt="Search" />
                    <input
                        placeholder="Search Expert"
                        value={searchText} // Bind state to input value
                        onChange={(e) => setSearchText(e.target.value)} // Update state on input change
                    />
                </div>
                <CustomDataTable
                    columns={columns}
                    data={getallsource?.data?.data}
                />
                <ReactPaginate
                    previousLabel={' Previous'}
                    previousClassName="previous-class"
                    nextLabel={'Next '}
                    nextClassName="next-class"
                    pageCount={getallsource?.data?.data ? Math.ceil(getallsource?.data?.total / getallsource?.data?.per_page) : 0} // Assuming each page returns 10 items
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active-paginate'}
                    renderOnZeroPageCount={null}
                    breakLabel="..."
                />
            </div>
        </div>
    )
}
export default Expert;