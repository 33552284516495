import React, { useEffect, useState } from "react";
import AllQueryTab from "./AllQueryTab";
import ArchivedQueries from "./ArchivedQueries";
import { Helmet } from "react-helmet";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import NoCloseModal from "../modal/NoCloseModal";
import CheckCompleted from "../modal/CheckCompleted";
import Sidebar from "../components/Sidebar";

const MyQueries = () => {
    const { user } = useProtectedRoutesContext();

    const [isAddQueryModalOpen, setIsAddQueryModalOpen] = useState(false);
    const [isAddQueryModalOpen1, setIsAddQueryModalOpen1] = useState(false);

    let has_completed_profile = '';

    useEffect(() => {
        if (has_completed_profile === false) {
            setIsAddQueryModalOpen1(true);
        }
    }, [has_completed_profile]);

    return (
        <>
            <div>
                <Helmet>
                    <title>My Queries</title>
                </Helmet>
                <div className="the-parent-sidebar">
                    <Sidebar />
                    <div className="main-left">
                        <div className="for-flex">
                            <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                            <button className="create-a-query" onClick={() => setIsAddQueryModalOpen(true)}>
                                Create a Query
                            </button>
                        </div>

                        <p className="my-queries-sub">My Queries</p>

                        <ul className="nav nav-tabsub" id="myTab" role="tablist">
                            <li className="nav-iteming">
                                <a className="nav-linking active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">All Queries</a>
                            </li>
                            <li className="nav-iteming">
                                <a className="nav-linking" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Archived Queries</a>
                            </li>
                        </ul>

                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                {/* Pass the modal state to AllQueryTab */}
                                <AllQueryTab
                                    isAddQueryModalOpen={isAddQueryModalOpen}
                                    setIsAddQueryModalOpen={setIsAddQueryModalOpen}
                                />
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <ArchivedQueries />
                            </div>
                        </div>
                    </div>
                </div>
                <NoCloseModal isOpen={isAddQueryModalOpen1} setIsOpen={setIsAddQueryModalOpen1} title="Update Profile to Proceed" size="xsm">
                    <CheckCompleted setIsOpen1={setIsAddQueryModalOpen1} />
                </NoCloseModal>
            </div>
        </>
    );
}

export default MyQueries;
