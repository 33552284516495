import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";

function AuthLayout() {
    const { user, token } = useProtectedRoutesContext();

    if (!!user && !!token) return <Navigate to="/tags" />;

    return (
        <div className="bg-[#F4F5FA] w-full min-h-[100vh] flex items-center justify-center">
            <Outlet />
        </div>
    );
}

export default AuthLayout;
