import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";

const SidebarContainer = styled.div`
  width: 270px;
  height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  position: fixed;
  z-index: 1000;
  transition: transform 0.3s ease;

  /* Sidebar hidden on mobile, visible on desktop */
  @media (max-width: 768px) {
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  }
`;

const SidebarLink = styled(NavLink)`
  color: #333;
  text-decoration: none;
  padding: 15px 20px;
  font-size: 16px;

  &.active {
    background-color: #0C78F1; /* Active color */
    color: #fff;
  }

  &:hover {
    background-color: #0C78F1;
    color: #fff;
  }
  
  &.active:hover {
    color: #fff;
  }
`;

const ToggleButton = styled.button`
  display: none;
  position: fixed;
  top: 15px;
  left: 15px;
  background-color: #0C78F1;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  z-index: 1100;

  /* Show toggle button only on mobile */
  @media (max-width: 768px) {
    display: block;
  }
`;

const Sidebar = () => {
  const { setUser, setToken } = useProtectedRoutesContext();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <ToggleButton onClick={toggleSidebar}>
        {isSidebarOpen ? 'Close' : 'Menu'}
      </ToggleButton>
      <SidebarContainer isOpen={isSidebarOpen}>
        <SidebarLink to="/tags" activeClassName="active" onClick={() => setSidebarOpen(false)}>
          Tags
        </SidebarLink>
        <SidebarLink to="/outlet" activeClassName="active" onClick={() => setSidebarOpen(false)}>
          Media Outlets
        </SidebarLink>
        <SidebarLink to="/journalists" activeClassName="active" onClick={() => setSidebarOpen(false)}>
          Journalists
        </SidebarLink>
        <SidebarLink to="/sources" activeClassName="active" onClick={() => setSidebarOpen(false)}>
          Experts
        </SidebarLink>
        <SidebarLink to="/admin" activeClassName="active" onClick={() => setSidebarOpen(false)}>
          Admins
        </SidebarLink>
        <SidebarLink to="/allqueries" activeClassName="active" onClick={() => setSidebarOpen(false)}>
          Queries
        </SidebarLink>
        <SidebarLink to="/allleaks" activeClassName="active" onClick={() => setSidebarOpen(false)}>
          Leaks
        </SidebarLink>
        <div className="qwerty111" onClick={() => {
          setToken(null);
          setUser(null);
          localStorage.clear();
          window.location.reload();
        }}>
          Logout
        </div>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
