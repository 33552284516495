import { useMutation } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { CreateAdmin } from "../api";
import { Formik } from "formik";



const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: ""
}
const AddAdmin = ({ setIsOpen }) => {
    const { mutate, isLoading } = useMutation(CreateAdmin, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            setIsOpen(false);
            // refetch();
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),
    });
    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    mutate(values)
                }}

            >
                {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <form className="flex flex-col" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <label className="query-label">Firstname *</label>
                                <input type="text"
                                    className="form-control query-modal-div-div"
                                    name="first_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.first_name}
                                ></input>
                            </div>
                            <div className="col-lg-6">
                                <label className="query-label">Lastname *</label>
                                <input type="text"
                                    className="form-control query-modal-div-div"
                                    name="last_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.last_name}
                                ></input>
                            </div>
                            <div className="col-lg-12">
                                <label className="query-label">Email *</label>
                                <input type="text"
                                    className="form-control query-modal-div-div"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                ></input>
                            </div>
                            <div className="col-lg-12">
                                <label className="query-label">Password *</label>
                                <input type="text"
                                    className="form-control query-modal-div-div"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                ></input>
                            </div>
                        </div>
                        <div className="create-query-div">
                            <button type="submit" className="create-query-btn">Create Admin</button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )

}
export default AddAdmin;