import { useMutation } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { CreateTag } from "../api";
import { Formik } from "formik";



const initialValues = {
    name: "",
}
const AddTags = ({ setIsOpen }) => {
    const { mutate, isLoading } = useMutation(CreateTag, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            setIsOpen(false);
            // refetch();
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),
    });
    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    mutate(values)
                }}

            >
                {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <form className="flex flex-col" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-12">
                                <label className="query-label">Tag Name *</label>
                                <input type="text"
                                    className="form-control query-modal-div"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                ></input>
                            </div>
                        </div>
                        <div className="create-query-div">
                            <button type="submit" className="create-query-btn">Create Tag</button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )

}
export default AddTags;